import React from "react"
import { Layout } from "components/layouts/Layout/layout"

export default function NotFoundPage() {
  return (
    <Layout>
      <div className="m-8">
        <h1 className="text-5xl font-bold text-idc-title">Page Not Found</h1>
        <p className="mt-8 text-lg font-medium text-idc-body">
          Sorry, we couldn&apos;t find what you were looking for
        </p>
      </div>
    </Layout>
  )
}
